import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

export default function useList() {
  // Use toast
  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true, },
    { key: 'name', label: 'Name', sortable: true },
    { key: 'actions', sortable: false }
  ]
  const perPage = ref(10)
  const totalRows = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const rows = ref([])
  const search = ref('')
  // const workcenters = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * currentPage.value,
      of: totalRows.value,
    }
  })
  function GetList() {
    return new Promise((resolve, reject) => {
      store.dispatch('pamis-unit/GetUnitTypes')
        .then(response => {
          if (response.data != null) {
            rows.value = response.data
            rows.value = rows.value.reverse();
            resolve(response.data);
          }
          else if (response.data.length === 0) {
            rows.value = [];
          }
        }).catch(e => reject(e))
    })
  }
  async function GetData() {
    await GetList()
  }

  const refetchData = async () => {
    await GetData()
  }
  GetData()

  return {
    rows,
    tableColumns,
    perPage,
    currentPage,
    search,
    totalRows,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refListTable,
    refetchData,
  }
}
