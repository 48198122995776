<template>
  <div>
    <div class="m-2">
      <b-row>
        <b-col
          cols="4"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="perPage"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label>
        </b-col>
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="search"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
            <b-button variant="primary" @click="AddRow">
              <span class="text-nowrap">Add Unit Type</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-table
      ref="refCommentListTable"
      class="position-relative"
      :items="rows"
      :fields="tableColumns"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="search"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
    >
      <template #cell(name)="data">
        <span v-if="!data.item.editing">{{ data.item.name }}</span>
        <b-form-input v-else v-model="data.item.temp"></b-form-input>
      </template>
      <template #cell(actions)="data">
        <feather-icon
          v-if="!data.item.editing"
          :id="`invoice-row-${data.item.rowId}-preview-icon`"
          icon="Edit2Icon"
          size="16"
          class="cursor-pointer mx-50"
          @click="EditRow(data.item)"
        />
        <feather-icon
          v-if="!data.item.editing"
          :id="`invoice-row-${data.item.rowId}-preview-icon`"
          icon="Trash2Icon"
          size="16"
          class="cursor-pointer mx-50"
          @click="DeleteRow(data.item)"
        />
        <feather-icon
          v-if="data.item.editing"
          :id="`invoice-row-${data.item.rowId}-preview-icon`"
          icon="CornerUpLeftIcon"
          size="16"
          class="cursor-pointer mx-50"
          @click="CancelEditing(data.item)"
        />
        <feather-icon
          v-if="data.item.editing"
          :id="`invoice-row-${data.item.rowId}-preview-icon`"
          icon="SaveIcon"
          size="16"
          class="cursor-pointer mx-50"
          @click="SaveRow(data.item)"
        />
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
            {{ dataMeta.of }} entries</span
          >
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <b-modal
      id="modalCancelDelete"
      ref="refCancelDelete"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      title="Please confirm"
      size="md"
      no-close-on-backdrop
      @ok="handleCancelDelete"
    >
      <span>This comment will be deleted, are you sure?</span>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BFormInput,
  VBModal,
  BButton,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import Vue from "vue";
import useUnitTypeList from "./useUnitTypeList";
import jobStoreModule from "../../unitStoreModule";

export default {
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    vSelect,
    // DateFilter,
  },
  setup() {
    const refCancelDelete = ref();
    const commentId = ref(0);
    const isAddRowAction = ref(false);
    const PAMIS_MODULE_NAME = "pamis-unit";
    // Register module
    if (!store.hasModule(PAMIS_MODULE_NAME)) {
      store.registerModule(PAMIS_MODULE_NAME, jobStoreModule);
    }
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PAMIS_MODULE_NAME)) {
        store.unregisterModule(PAMIS_MODULE_NAME);
      }
    });

    const {
      rows,
      tableColumns,
      perPage,
      currentPage,
      search,
      totalRows,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
    } = useUnitTypeList();
    function EditRow(item) {
      Vue.set(item, "temp", item.comment);
      Vue.set(item, "editing", true);
    }
    function CancelEditing(item) {
      Vue.set(item, "editing", false);
      if (isAddRowAction.value) {
        rows.value.splice(0, 1);
        isAddRowAction.value = false;
      }
    }

    function AddRow() {
      if (!isAddRowAction.value) {
        isAddRowAction.value = true;
        rows.value.unshift({
          editing: true,
          temp: "",
        });
      } else {
        //show
      }
    }
    function DeleteRow(item) {
      commentId.value = item.id;
      refCancelDelete.value.show();
    }
    function handleCancelDelete() {
      store
        .dispatch("pamis-unit/DeleteUnitType", commentId.value)
        .finally((e) => {
          refetchData();
        });
    }
    function SaveRow(item) {
      if (isAddRowAction.value) {
        store
          .dispatch("pamis-unit/CreateUnitType", {
            name: item.temp,
          })
          .finally((e) => {
            refetchData();
          });
        isAddRowAction.value = false;
      } else {
        store
          .dispatch("pamis-unit/UpdateUnitType", {
            id: item.id,
            name: item.temp,
          })
          .finally((e) => {
            refetchData();
          });
      }
      Vue.set(item, "editing", false);
    }

    return {
      refCancelDelete,
      handleCancelDelete,
      AddRow,
      EditRow,
      DeleteRow,
      CancelEditing,
      SaveRow,
      rows,
      tableColumns,
      perPage,
      currentPage,
      search,
      totalRows,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
