import { ref, computed } from '@vue/composition-api'
import store from '@/store'


export default function useUnitList(refJobListTable) {
  // Use toast

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: 'Id', sortable: true },
    { key: 'name', label: 'Name', sortable: true },
    { key: 'symbol', label: 'Symbol', sortable: false },
    { key: 'abbreviation', label: 'Abbreviation', sortable: false },
    { key: 'conversionRatios', label: 'Conversion Ratio', sortable: false },
    { key: 'description', label: 'Description', sortable: false },
    { key: 'accuracy', label: 'Accuracy', sortable: false },
    { key: 'unitType.name', label: 'Unit Type', sortable: false },
    { key: 'action', thClass: "pr-50", sortable: false },
  ]
  const perPage = ref(10)
  const totalJobs = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const rows = ref([])
  const search = ref('')
  // const workcenters = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refJobListTable.value ? refJobListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * currentPage.value,
      of: totalJobs.value,
    }
  })
  /*
    watch([currentPage, perPage, search], () => {
      refetchData()
    })
  */
  function GetDataColectionJobs() {
    return new Promise((resolve, reject) => {
      store.dispatch('pamis-unit/fetchUnits')
        .then(response => {
          if (response.data != null && response.data.length > 0) {
            rows.value = response.data
            totalJobs.value = rows.value.length
            rows.value.forEach(e => {
              e.device = `${e.device.name} (${e.device.id})`;
            })
            resolve(response.data)
          } else {
            reject(response.data)
          }
        })
        .catch(() => {
        })
    })
  }
  function GetJobFieldsData() {
    return store.dispatch('pamis-unit/fetchUnits')
  }
  async function GetData() {
    await GetJobFieldsData()
    await GetDataColectionJobs()
  }
  GetData()
  return {
    GetData,
    rows,
    tableColumns,
    perPage,
    currentPage,
    search,
    totalJobs,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refJobListTable,
  }
}
