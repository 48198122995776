<template>
  <div>
    <unit-add-new
      :is-add-new-job-sidebar-active.sync="isAddNewJobSidebarActive"
      :selected-job="selectedJob"
      :action-type="actionType"
      @refetch-data="refetchData"
    />
    <!-- Table Container Card -->

    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col
            cols="4"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-button class="mx-1" variant="success" @click="openUnitType">
                <span class="text-nowrap">Unit Types</span>
              </b-button>
              <b-form-input
                v-model="search"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button variant="primary" @click="newUnit">
                <span class="text-nowrap">+ Add Unit</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refJobListTable"
        class="position-relative"
        :items="rows"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="search"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(action)="data">
          <feather-icon
            :id="`invoice-row-${data.item.id}-edit-icon`"
            icon="Edit3Icon"
            size="16"
            class="cursor-pointer mx-50"
            @click="changeUnit(data.item, 1)"
          />
          <feather-icon
            :id="`invoice-row-${data.item.id}-trash-icon`"
            icon="XIcon"
            size="16"
            class="cursor-pointer mx-50"
            @click="cancelJobConfirm(data.item)"
          />
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalJobs"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

      <b-modal
        id="modaljobComment"
        ref="refUnitType"
        cancel-variant="outline-secondary"
        cancel-title="Close"
        centered
        title="Unit Types"
        size="lg"
        no-close-on-backdrop
      >
        <template>
          <unit-type-list />
        </template>
        <template #modal-footer>
          <b-button variant="outline-secondary" @click="closeCommentModal">
            Close
          </b-button>
        </template>
      </b-modal>

      <b-modal
        id="modalCancelJob"
        ref="refCancelJob"
        cancel-variant="outline-secondary"
        cancel-title="Close"
        centered
        title="Please confirm"
        size="md"
        no-close-on-backdrop
        @ok="cancelJob"
      >
        <span>
          This job's status will be changed to canceled. Are you sure?
        </span>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BModal,
  VBModal,
  BButton,
  BForm,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BFormTextarea,
  BFormText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import {
  inject,
  toRefs,
  watch,
  ref,
  onUnmounted,
  computed,
} from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import moment from "moment";
import Ripple from "vue-ripple-directive";
import useUnitList from "./useUnitList";
import unitStoreModule from "../unitStoreModule";
import UnitAddNew from "./UnitAddEdit.vue";
import UnitTypeList from "./UnitType/UnitTypeList.vue";
// import DateFilter from '../components/DateFilter.vue'

export default {
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  components: {
    UnitAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BModal,
    VBModal,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    UnitTypeList,
    BDropdownItem,
    BPagination,
    vSelect,
    // DateFilter,
    BForm,
    BTable,
    BFormGroup,
    BFormTextarea,
    BFormText,
  },
  setup() {
    const PAMIS_MODULE_NAME = "pamis-unit";
    const refJobListTable = ref(null);
    const refUnitType = ref(null);
    const refCancelJob = ref(null);
    const selectedJob = ref(null);
    const id = ref(0);
    const actionType = ref(0);
    const isCommentActive = ref(false);
    const isAddNewJobSidebarActive = ref(false);
    const jobId = ref(0);
    // Register module
    if (!store.hasModule(PAMIS_MODULE_NAME)) {
      store.registerModule(PAMIS_MODULE_NAME, unitStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PAMIS_MODULE_NAME)) {
        store.unregisterModule(PAMIS_MODULE_NAME);
      }
    });
    const {
      GetData,
      rows,
      tableColumns,
      perPage,
      currentPage,
      totalJobs,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      search,
    } = useUnitList(refJobListTable);
    const refetchData = () => {
      GetData();
    };

    const changeUnit = (e, actType) => {
      actionType.value = actType;
      selectedJob.value = e;
      isAddNewJobSidebarActive.value = true;
    };

    const newUnit = () => {
      selectedJob.value = {
        Id: null,
        name: null,
        code: null,
        jobTypeID: null,
        workcenterID: null,
        isActive: true,
      };
      actionType.value = 0;
      isAddNewJobSidebarActive.value = true;
    };
    function cancelJob() {
      store.dispatch("pamis-unit/deleteUnit", jobId.value).then(() => {
        refetchData();
      });
    }
    store.dispatch("pamis-unit/GetUnitTypes");
    const cancelJobConfirm = (data) => {
      jobId.value = data.id;
      refCancelJob.value.show();
    };
    const openUnitType = () => {
      refUnitType.value.show();
    };
    const closeCommentModal = () => {
      refUnitType.value.hide();
    };
    watch(isAddNewJobSidebarActive, (val) => {
      if (!val && actionType.value === 1) {
        selectedJob.value = null;
      }
    });

    return {
      id,
      isCommentActive,
      actionType,
      newUnit,
      selectedJob,
      changeUnit,
      cancelJob,
      cancelJobConfirm,
      openUnitType,
      closeCommentModal,
      isAddNewJobSidebarActive,
      jobId,
      rows,
      tableColumns,
      perPage,
      currentPage,
      totalJobs,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refJobListTable,
      refUnitType,
      refCancelJob,
      refetchData,
      search,
      // Filter
      avatarText,
    };
  },
};
</script>

<style lang="scss" scoped>
table.text-center * {
  text-align: center;
}
.status-badge {
  margin-left: -10px;
  font-size: 13px;
}
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
