import axios from '@axios'

export default {
  namespaced: true,
  state: { unitTypes: [] },
  getters: { GetUnitTypes(state) { return state.unitTypes } },
  mutations: {
    SetUnitTypes(state, data) {
      state.unitTypes = data;
    },
  },
  actions: {
    fetchUnits(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/parameter/Unit/Units')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    createUnit(ctx, unitData) {
      return new Promise((resolve, reject) => {
        axios
          .post('pamis/admin/parameter/Unit/Create', unitData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateUnit(ctx, unitData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`pamis/admin/parameter/Unit/Update`, unitData, { params: { id: unitData.unit.id } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteUnit(ctx, unitId) {
      return new Promise((resolve, reject) => {
        axios
          .delete('pamis/admin/parameter/Unit/Delete', { params: { id: unitId } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    GetUnitTypes(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/parameter/UnitType/UnitTypes')
          .then(response => {
            ctx.commit("SetUnitTypes", response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    CreateUnitType(ctx, unitData) {
      return new Promise((resolve, reject) => {
        axios
          .post('pamis/admin/parameter/UnitType/Create', { unitTypeDto: unitData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    UpdateUnitType(ctx, unitData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`pamis/admin/parameter/UnitType/Update`, { unitType: unitData }, { params: { id: unitData.id } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteUnitType(ctx, unitId) {
      return new Promise((resolve, reject) => {
        axios
          .delete('pamis/admin/parameter/UnitType/Delete', { params: { id: unitId } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
